.codeEditor {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(37, 37, 38);

  .codeEditorSubheader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .codeEditorArea {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    align-self: stretch;
    flex: 1 1 auto;
  }
}
