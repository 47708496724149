.codeEditorArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  background-color: rgb(37, 37, 38);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05000000074505806);

  .codeArea {
    align-self: stretch;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}