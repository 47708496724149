.headerItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

}
.headerItem:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}