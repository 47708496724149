.codeEditorLeftNav {
  display: flex;
  flex-direction: row;
  overflow: auto;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-left: 0;
  }
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05000000074505806);

  .codeEditorIcons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 1.5rem;
  }
}
.codeEditorLeftNav {
  display: flex;
  overflow: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 25rem;
  &:nth-child(1) {
    margin-left: 0;
  }
  background-color: rgb(255, 255, 255);

  .codeEditorIcons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 1.5rem;
  }

  .content {
    overflow: auto;
    display: flex;
    width: 25rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    > * {
      margin-top: 2rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    background-color: rgb(255, 255, 255);
  }

  .settingsContent {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}
.codeEditorLeftNav {
  display: flex;
  overflow: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 25rem;
  &:nth-child(1) {
    margin-left: 0;
  }
  background-color: rgb(255, 255, 255);

  .codeEditorIcons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 1.5rem;
  }

  .content {
    overflow: auto;
    display: flex;
    width: 25rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    > * {
      margin-top: 2rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    background-color: rgb(255, 255, 255);
  }

  .tagsContent {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}
