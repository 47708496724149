.spindlLogo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .logo {
    align-self: flex-start;
    width: 5.1875rem;
    flex: 0 0 2rem;
  }

  .vector {
  }

}