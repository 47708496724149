.templateCardList {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 2rem;
    margin-left: 2rem;
  }

  .templateCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    height: 14.4375rem;
    flex: 1 1 auto;
    max-width: calc(25% - 1.5rem);
    @media (max-width: 1439px) {
      &:nth-child(-n+4) {
        margin-top: 0;
      }
      &:nth-child(4n+1) {
        margin-left: 0;
      }
    }
  }

}

@media (min-width: 1440px) {
  .templateCardList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 2rem;
      margin-left: 2rem;
    }
  
    .templateCard {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      height: 16.4375rem;
      flex: 1 1 auto;
      max-width: calc(25% - 1.5rem);
      @media (max-width: 1919px) {
        &:nth-child(-n+4) {
          margin-top: 0;
        }
        &:nth-child(4n+1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1920px) {
  .templateCardList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 2.5rem;
      margin-left: 2.5rem;
    }
  
    .templateCard {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      height: 19.125rem;
      flex: 1 1 auto;
      max-width: calc(25% - 1.875rem);
      &:nth-child(-n+4) {
        margin-top: 0;
      }
      &:nth-child(4n+1) {
        margin-left: 0;
      }
    }
  
  }
}