.serviceCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  > * {
    margin-top: 0.75rem;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05000000074505806);

  .imageContainers {
    align-self: center;
    width: 4rem;
    flex: 0 0 4rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .serviceName {
    align-self: center;
    width: 13.375rem;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(37, 37, 38);
  }

  .templatesCount {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(37, 37, 38);
  }

  .tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    > * {
      margin-left: 1.5rem;
    }
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}