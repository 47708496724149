.appInfo {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .icon {
    align-self: center;
    min-height: 2.5rem;
    flex: 0 0 2.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
    background-color: rgb(232, 231, 228);
    border-radius: 4px;
  }

  .appName {
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .dividers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

}