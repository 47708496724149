.serviceBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 1rem 2rem 1rem;
  > * {
    margin-top: 1.5rem;
  }

  .title {
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .serviceCardList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 2rem;
      margin-left: 2rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 1440px) {
  .serviceBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 2.5rem 2rem 2.5rem;
    > * {
      margin-top: 1.5rem;
    }
  
    .title {
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      font-family: 'Inter', 'sans-serif';
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.5rem;
      text-align: left;
      color: rgb(37, 37, 38);
    }
  
    .serviceCardList {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 2rem;
        margin-left: 2rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1920px) {
  .serviceBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.5rem 8.75rem 2.5rem 8.75rem;
    > * {
      margin-top: 1.5rem;
    }
  
    .title {
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      font-family: 'Inter', 'sans-serif';
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.5rem;
      text-align: left;
      color: rgb(37, 37, 38);
    }
  
    .serviceCardList {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 2.5rem;
        margin-left: 2.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
  }
}