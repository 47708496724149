.mainLayout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(245, 245, 245);

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 0.75rem 1.25rem 0.75rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .body {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .leftNav {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 15rem;
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1.875rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .designLibrary {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 1199px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 1200px) {
  .mainLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(245, 245, 245);
  
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 0.75rem 1.25rem 0.75rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .leftNav {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 15rem;
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.875rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .designLibrary {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .mainLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(245, 245, 245);
  
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 0.75rem 1.25rem 0.75rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .leftNav {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 15rem;
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.875rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .designLibrary {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.875rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .mainLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(245, 245, 245);
  
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 0.75rem 1.25rem 0.75rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .leftNav {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 15rem;
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.875rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .designLibrary {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.875rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1920px) {
  .mainLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(245, 245, 245);
  
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 0.75rem 1.25rem 0.75rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .leftNav {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 15rem;
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.875rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .designLibrary {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.875rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1920px) {
  .mainLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(245, 245, 245);
  
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 0.75rem 1.25rem 0.75rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .leftNav {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 15rem;
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.875rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .designLibrary {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.875rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
  }
}