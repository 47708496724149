.details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 0.125rem;
  }

  .label {
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

  .value {
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

}