.imageContainers40px {
  align-self: flex-start;
  width: 2.5rem;
  flex: 0 0 2.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-image: url('../../../resources/images/ImageContainers40px.png');
  background-size: cover;

}
.imageContainers64px {
  align-self: flex-start;
  width: 4rem;
  flex: 0 0 4rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-image: url('../../../resources/images/ImageContainers64px.png');
  background-size: cover;

}
.imageContainers3x2 {
  align-self: flex-start;
  width: 13rem;
  flex: 0 0 8.6875rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-image: url('../../../resources/images/ImageContainers3x2.png');
  background-size: cover;

}