.templateCardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0.75rem 1rem 0.75rem;
  > * {
    margin-top: 0.25rem;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05000000074505806);

  .templateName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .label1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.125rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .label2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.125rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}