.templateCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05000000074505806);

  .imageContainers {
    align-self: stretch;
    flex: 1 1 auto;
  }

  .templateCardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0.75rem 1rem 0.75rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

}

@media (min-width: 1440px) {
  .templateCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05000000074505806);
  
    .imageContainers {
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .templateCardContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.75rem 0.75rem 1rem 0.75rem;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1920px) {
  .templateCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05000000074505806);
  
    .imageContainers {
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .templateCardContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.75rem 0.75rem 1rem 0.75rem;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}