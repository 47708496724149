.codeEditorHeader {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05000000074505806);

  .backButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .headerItems {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
  }

}