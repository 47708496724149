.dividers {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    border: 1px solid rgb(232, 231, 228);
  }
}
