.tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  > * {
    margin-left: 0.625rem;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 2px;

  .value {
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

}