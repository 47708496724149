.confirmationModalContainer {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmationModal {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  > * {
    margin-top: 1.5rem;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 8px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .closeFrame {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .icon {
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.75rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .modalTitle {
    align-self: flex-start;
    width: 28.25rem;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .modalBody {
    align-self: flex-start;
    width: 28.25rem;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .buttonGroup {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 1rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}