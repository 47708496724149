.headerItems {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .uploadHtml {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .dividers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .sendEmail {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

}