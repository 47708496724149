.userIcon {
  align-self: flex-start;
  height: 2rem;
  flex: 0 0 2rem;
  &:nth-child(1) {
    margin-left: 0;
  }

}
.userIcon {
  align-self: flex-start;
  height: 2rem;
  flex: 0 0 2rem;
  &:nth-child(1) {
    margin-left: 0;
  }

  .background {
  }

  .value {
    font-family: 'Inter', 'sans-serif';
    font-size: 0.9000000357627869rem;
    font-weight: 600;
    line-height: 1.2000000476837158rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}