.icon {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
  }

}