.menuItemList {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05000000074505806);

  .menuItem {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0.5rem 0rem 0.5rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

}