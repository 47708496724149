.sendTestModal {
  overflow: hidden;
  display: flex;
  width: 21.75rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  .notch {
    align-self: flex-end;
    margin-right: 1.5rem;
    width: 1rem;
    flex: 0 0 0.5rem;
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    > * {
      margin-top: 0.75rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
  }

  .title {
    align-self: flex-start;
    width: 8.5625rem;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(37, 37, 38);
  }

  .textBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    > * {
      margin-left: 1.5rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}
