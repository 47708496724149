.templateName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .serviceName {
    align-self: center;
    flex: 0 0 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}