.menuItemBody {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 4px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .value {
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .expandIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuItemBody:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .value {
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .expandIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuItemBody:selected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .leftBorder {
    align-self: center;
    height: 3rem;
    flex: 0 0 0.25rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 0.75rem 0rem 0rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .value {
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .expandIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuItemBody {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 4px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .value {
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .expandIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuItemBody:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .value {
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .expandIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuItemBody:selected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .leftBorder {
    align-self: center;
    height: 3rem;
    flex: 0 0 0.25rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 0.75rem 0rem 0rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .value {
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(37, 37, 38);
  }

  .expandIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}