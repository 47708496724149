.codeEditorIcons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-top: 1rem;
  }

  .settingsIcon {
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .tagIcon {
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}