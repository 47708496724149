.buttonBrand {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  > * {
    margin-left: 1.5rem;
  }
  background-color: rgb(0, 117, 225);
  border-radius: 4px;

  .content {
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.buttonBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  > * {
    margin-left: 1.5rem;
  }
  background-color: rgb(0, 84, 163);
  border-radius: 4px;

  .content {
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.buttonBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  > * {
    margin-left: 1.5rem;
  }
  background-color: rgb(0, 117, 225);
  border: 2px solid rgb(200, 228, 255);
  border-radius: 4px;

  .content {
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.buttonBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  > * {
    margin-left: 1.5rem;
  }
  background-color: rgb(200, 228, 255);
  border-radius: 4px;

  .content {
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.buttonBasic {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  > * {
    margin-left: 1.5rem;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .content {
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(37, 37, 38);
  }

}
.buttonBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  > * {
    margin-left: 1.5rem;
  }
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .content {
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(37, 37, 38);
  }

}
.buttonBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  > * {
    margin-left: 1.5rem;
  }
  background-color: rgb(232, 231, 228);
  border: 2px solid rgb(158, 158, 158);
  border-radius: 4px;

  .content {
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(37, 37, 38);
  }

}
.buttonBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  > * {
    margin-left: 1.5rem;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .content {
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(189, 189, 189);
  }

}