.serviceCardList {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 2rem;
    margin-left: 2rem;
  }

  .serviceCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    > * {
      margin-top: 0.75rem;
    }
    align-self: flex-start;
    height: 14rem;
    flex: 1 1 auto;
    max-width: calc(33.333333333333336% - 1.3333333333333333rem);
    @media (max-width: 1919px) {
      &:nth-child(-n+3) {
        margin-top: 0;
      }
      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }
  }

}

@media (min-width: 1920px) {
  .serviceCardList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 2.5rem;
      margin-left: 2.5rem;
    }
  
    .serviceCard {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      > * {
        margin-top: 0.75rem;
      }
      align-self: flex-start;
      height: 14rem;
      flex: 1 1 auto;
      max-width: calc(33.333333333333336% - 1.6666666666666667rem);
      &:nth-child(-n+3) {
        margin-top: 0;
      }
      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }
  
  }
}