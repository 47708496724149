.blockHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    align-self: center;
    flex: 0 0 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    > * {
      margin-left: 1.5rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

}